import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PossibleCalendarTypes } from "../MainCalendar";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import { usePersistantStore } from "@/stores/global";

interface Tag {
  id: number;
  type: "RESOURCE" | "HUMAN" | "CREW";
  name: string;
  region: {id: number; name: string;}
}

export default function SelectCalendarDialog({
  type,
  reference,
  onChange,
}: {
  type: PossibleCalendarTypes;
  reference: number | null;
  onChange: (type: PossibleCalendarTypes, reference: number | null) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const region = usePersistantStore(state => state.region);
  const [regionCheck, setRegionCheck] = useState(region);
  const open = !!anchorEl;
  const open2 = !!anchorEl2;
  const firstRender = useRef(true);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const tagNames = useReferenceNames<Tag, Tag>("resourceTag", (o) => o);

  const [resources, staffs, crews] = useMemo(() => {
    const r: Tag[] = [];
    const s: Tag[] = [];
    const c: Tag[] = [];
    for (const t of tagNames.values()) if (["CALENDAR_ONLY","WISHABLE","REQUESTABLE"].includes(t?.calendarType || "") && (region === 0 || t.region.id === region) && !t?.usesShortPlanning) (t.type === "HUMAN" ? s : t.type === "RESOURCE" ? r : c).push(t);
    return [r, s, c];
  }, [tagNames, region]);

  useEffect(() => {
    if (region === regionCheck) return;
    setRegionCheck(region);
    if (tagNames.get(reference ?? 0)) onChange("CREWS", crews[0].id);
  }, [region]);

  useEffect(() => {
    if ((!type || !["CREWS","STAFF","RESOURCES","PROJECTS"].includes(type)) && crews.length > 0) onChange("CREWS", crews[0].id);
  }, [type, crews]);


  const title =
    type === "CREWS"
      ? "Kolonnen"
      : type === "STAFF"
      ? "Mitarbeiter"
      : type === "RESOURCES"
      ? "Maschinen"
      : type === "PROJECTS"
      ? "Baustellen"
       : "Lädt...";

  const hasSecondMenu =
    type === "STAFF" || type === "RESOURCES" || type === "CREWS";

  const title2 = !hasSecondMenu
    ? ""
    : (tagNames.get(reference ?? 0)?.name || "Unbekannt") + (region === 0 ? " ("+(tagNames.get(reference ?? 0)?.region.name || "Unbekannt")+")" : "");

  return (
    <div>
      <Button onClick={ handleClick} endIcon={<ArrowDropDown />}>
        {title}
      </Button>
      {hasSecondMenu ? (
        <Button onClick={handleClick2} endIcon={<ArrowDropDown />}>
          {title2}
        </Button>
      ) : null}
      {hasSecondMenu ? (
        <Menu
          open={open2}
          anchorEl={anchorEl2}
          onClose={close}
          slotProps={{
            paper: {
              style: {
                maxHeight: "400px",
              },
            },
          }}
        >
          {(type === "RESOURCES"
            ? resources :
              type === "CREWS" ? 
              crews
            : staffs
          ).map((x) => (
            <MenuItem
              key={x.id}
              onClick={() => {
                onChange(type, x.id);
                close();
              }}
            >
              {(x.name || "Unbekannt") + (region === 0 ? " ("+x.region.name+")" : "")}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
      <Menu open={open} anchorEl={anchorEl} onClose={close}>
        <MenuItem
          onClick={() => {
            type === "PROJECTS" || onChange("PROJECTS", null);
            close();
          }}
        >
          Baustellen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "CREWS" || onChange("CREWS", crews.length > 0 ? crews[0].id : 0);
            close();
          }}
        >
          Kolonnen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "RESOURCES" ||
              onChange("RESOURCES", resources.length > 0 ? resources[0].id : 0);
            close();
          }}
        >
          Maschinen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "STAFF" ||
              onChange("STAFF", staffs.length > 0 ? staffs[0].id : 0);
            close();
          }}
        >
          Mitarbeiter
        </MenuItem>
      </Menu>
    </div>
  );
}
