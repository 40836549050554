import { Fragment, useMemo, useState } from "react";
import {
  Article,
  MaterialOrder,
  OrderPosition,
  SupplierOrder as SO,
} from "../Project";
import ApiService from "@/api/ApiService";
import LabelWrapper from "@/components/root/LabelWrapper";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import EnhancedTable from "@/shared/views/EnhancedTable";
import {
  Search,
  ArrowForward,
  ExpandMore,
  ArrowBack,
} from "@mui/icons-material";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  CircularProgress,
  Alert,
  AlertTitle,
  Button,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import SupplierOrder from "../elements/SupplierOrder";

const headCells = [
  {
    id: "number",
    label: "Artikelnummer",
    numeric: true,
    sortable: true,
  },
  {
    id: "name",
    label: "Artikelname",
    numeric: false,
    sortable: true,
  },
  {
    id: "plantName",
    label: "Lieferwerk",
    numeric: false,
    sortable: true,
  },
  {
    id: "supplierName",
    label: "Lieferant",
    numeric: false,
    sortable: true,
  },
];

function BauhofSelector({ data, onSelect, initiallyOpen }) {
  const [category, setCategory] = useState("");
  const [categories, articles] = useMemo(() => {
    const upper =
      category === "" ? false : category.split(";").slice(0, -1).join(";");
    const currentDepth = category === "" ? 0 : category.split(";").length;
    const categories: {
      [link: string]: { title: any; link: string; upper: boolean };
    } = {};
    const articles: Article[] = [];
    for (let a of data.articles) {
      const cat = a.category ?? "";
      if (cat === category) {
        articles.push(a);
      } else if (category === "" || cat.startsWith(category + ";")) {
        const newCat = cat.split(";").slice(0, currentDepth + 1);
        const link = newCat.join(";");
        const title = newCat[newCat.length - 1];
        categories[title] = { title, link, upper: false };
      }
    }

    return [
      (upper !== false
        ? [
            {
              upper: true,
              title: <ArrowBack />,
              link: upper,
            },
          ]
        : []
      ).concat(
        Object.values(categories).sort((a, b) => a.title.localeCompare(b.title))
      ),
      articles.sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }, [data, category]);

  console.log("BAUHOF", data, categories, articles);

  return (
    <Accordion disableGutters defaultExpanded={initiallyOpen}>
      <AccordionSummary
        sx={{
          background: "#C5372C",
          color: "white",
          borderBottom: "1px solid rgba(255,255,255,0.5)",
        }}
        expandIcon={<ExpandMore sx={{ color: "white" }} />}
      >
        <Typography sx={{ flexShrink: 0, flex: 1 }}>{data.name}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)", p: 0 }}
      >
        {categories.length ? (
          <div className="bbb_categoryWrapper">
            {categories.map((cat) => (
              <div
                className={"bbb_category" + (cat.upper ? " bbb_up" : "")}
                key={cat.link}
                onClick={() => setCategory(cat.link)}
              >
                <div className="bbb_catiner">{cat.title}</div>
              </div>
            ))}
          </div>
        ) : null}
        {articles.length ? (
          <div className="bbb_articleWrapper">
            {articles.map((art) => (
              <div
                className="bbb_article"
                key={art.id}
                onClick={() => onSelect(art)}
              >
                <div
                  className="bbb_image"
                  style={
                    !!art.image
                      ? {
                          backgroundImage:
                            "url(https://bob.juchem-gruppe.de/backend/api/image/" +
                            art.image +
                            ")",
                        }
                      : {}
                  }
                />
                <div className="bbb_text">
                  <div className="bbb_name">{art.name}</div>
                  <div className="bbb_number">{art.number}</div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}

export default function MaterialSelector({
  onChange,
  supplierOrders,
  abort,
  onlyBauhof,
  allowBaustellendienst,
  projectId,
}: {
  onChange: (
    change: Partial<MaterialOrder>,
    forceBaustellendienst: "DA" | "DAB" | "DELETE"
  ) => void;
  supplierOrders: SO[];
  allowBaustellendienst: boolean;
  projectId: number;
  onlyBauhof: number;
  abort?: () => void;
}) {
  const [manualSelection, setManualSelection] = useState(false);
  const search = useMutation<Article[], AxiosResponse, { search: string }>({
    mutationFn: ApiService.searchMaterials(),
    onSuccess: (answer) => {},
  });

  const bauhofArticleQuery = useQuery({
    queryKey: ["getBauhofArticles", projectId],
    queryFn: ApiService.getBauhofArticles(projectId),
  });

  const [searchField, setSearchField] = useState("");

  return (
    <Fragment>
      {!!abort ? (
        <div style={{ textAlign: "left", padding: "4px 15px" }}>
          <Button
            sx={{}}
            size="small"
            startIcon={<ArrowBack />}
            onClick={abort}
            variant="outlined"
          >
            zurück
          </Button>
        </div>
      ) : null}
      <LabelWrapper
        label={
          <Fragment>
            <Box>Artikelauswahl</Box>
            {onlyBauhof > 0 ? null : (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={manualSelection}
                      onChange={(e) => setManualSelection(e.target.checked)}
                    />
                  }
                  componentsProps={{ typography: { fontSize: "90%" } }}
                  sx={{ m: "0px" }}
                  label="Freie Auswahl"
                />
              </FormGroup>
            )}
          </Fragment>
        }
      >
        {manualSelection && onlyBauhof === 0 ? (
          <Fragment>
            <OutlinedInput
              sx={{ mb: "10px" }}
              fullWidth
              size="small"
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? search.mutate({
                      search: searchField,
                    })
                  : null
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={search.isPending}
                    onClick={() =>
                      search.mutate({
                        search: searchField,
                      })
                    }
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                      event.preventDefault()
                    }
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Artikel suchen..."
              value={searchField}
              onChange={(e) =>
                setSearchField((e.target as HTMLInputElement).value)
              }
            />
            {search.isIdle ? null : (
              <Paper variant="outlined">
                {search.isError ? (
                  <ErrorIndicator />
                ) : search.isPending ? (
                  <Box
                    sx={{
                      minHeight: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : search.data.length === 0 ? (
                  <Alert severity="info">
                    <AlertTitle>Nichts gefunden.</AlertTitle>
                    Hinweis: Sie müssen mindestens drei Zeichen eingeben und ein
                    Lieferwerk auswählen.
                  </Alert>
                ) : (
                  <EnhancedTable
                    buttons={(article: Article) => (
                      <IconButton
                        size="small"
                        aria-label="edit"
                        onClick={() =>
                          onChange(
                            {
                              position: null,
                              amount: 0,
                              article,
                            },
                            "DA"
                          )
                        }
                      >
                        <ArrowForward />
                      </IconButton>
                    )}
                    type=""
                    initialSort="name"
                    headCells={headCells}
                    rows={search.data}
                  />
                )}
              </Paper>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {bauhofArticleQuery.isLoading ||
            bauhofArticleQuery.isLoadingError ? (
              <CircularProgress />
            ) : (
              bauhofArticleQuery.data.map((x) =>
                onlyBauhof === 0 || x.id === onlyBauhof ? (
                  <BauhofSelector
                    key={x.id}
                    data={x}
                    initiallyOpen={onlyBauhof > 0}
                    onSelect={(article) =>
                      onChange({ position: null, article, amount: 0 }, "DELETE")
                    }
                  />
                ) : null
              )
            )}
            {supplierOrders.map((so, i) =>
              so.deleted ? null : (
                <SupplierOrder
                  so={so}
                  deleteOrder={null}
                  onSelect={(position: OrderPosition) =>
                    onChange(
                      {
                        position,
                        amount: 0,
                        article: position.article,
                      },
                      "DA"
                    )
                  }
                  key={i}
                />
              )
            )}
            {allowBaustellendienst ? (
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  sx={{ mt: "10px" }}
                  onClick={() =>
                    onChange(
                      {
                        position: null,
                        article: null,
                        amount: -1,
                        //transportInformation: (s.transportInformation?? []).map(x => ({...x, siteWork: true}))
                      },
                      "DAB"
                    )
                  }
                >
                  Nur Baustellendienst
                </Button>
              </div>
            ) : null}
          </Fragment>
        )}
      </LabelWrapper>
    </Fragment>
  );
}
