import { Box } from "@mui/material";
import type { XYCoord } from "react-dnd";
import { useDragLayer } from "react-dnd";

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    return <div
      className={
        "kgpcontent " +
        item.type
      }
    >
      <div className="kgpresname">
        <span className="kgpresnumber">{item.resource.number}</span>
        {item.resource.name}
      </div></div>;
  }

  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </Box>
  );
};
