import { useGlobalStore } from "@/stores/global";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import DatePicker from "react-date-picker";
import { IconButton, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

export default function DispoCalendarDateSelector({}) {
  const date = useGlobalStore((state) => state.getDate());
  const setDate = useGlobalStore((state) => state.setDate);
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Box sx={{display: "flex",gap:"5px", alignItems: "center"}}>
      <IconButton size="small" onClick={() => setDate(date.subtract(1, "day"))}>
        <ArrowBackIos fontSize="small" />
      </IconButton>
      <Box
        onClick={() => setCalendarOpen(true)}
        sx={{
          display: "flex",
          minWidth: "145px",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Typography variant="h6">{date.format("dd., DD.MM.YYYY")}</Typography>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            ".invisiblePicker .react-date-picker__wrapper": { display: "none" },
          }}
        >
          <DatePicker
            className={"invisiblePicker"}
            onChange={(date: Date) => setDate(dayjs(date))}
            isOpen={calendarOpen}
            clearIcon={null}
            onCalendarClose={() => {
              console.log("calendar  closes");
              setCalendarOpen(false);
            }}
            value={date.toDate()}
          />
        </Box>
      </Box>
      <IconButton size="small" onClick={() => setDate(date.add(1, "day"))}>
        <ArrowForwardIos fontSize="small" />
      </IconButton>
    </Box>
  );
}
