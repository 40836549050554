import {
  Paper,
  Button,
  Box,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import { Fragment, useCallback } from "react";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { ProjectState } from "../Project";
import { MainContainer } from "@/shared/structure/MainContainer";
import { TopBar } from "@/shared/structure/TopBar";
import AddFileDialog from "../dialogs/AddFileDialog";
import dayjs from "dayjs";

export default function FilePage({
  project,
  setField,
}: {
  project: ProjectState;
  setField: (changes: Partial<ProjectState>) => void;
}) {
  const deleteFile = useCallback(
    (index: number) => {
      setField({
        files: project.files.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
        })),
      });
    },
    [project]
  );

  const saveFile = useCallback(
    (subOrder: ProjectFile, index: number) => {
      if (index === -1) {
        const files = project.files.slice();
        files.push(subOrder);
        setField({
          files,
        });
      } else {
        setField({
          files: project.files
            .slice()
            .map((so, i) => (i === index ? subOrder : so)),
        });
      }
    },
    [project]
  );

  const fileCount = project.files.filter((x) => !x.deleted).length;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      {project.writeable ? <TopBar>
        <AddFileDialog
          save={(o) => saveFile(o, -1)}
          OpenButton={({ onClick }) => (
            <Button startIcon={<Add />} onClick={onClick}>
              Datei hinzufügen
            </Button>
          )}
          initialState={null}
          index={-1}
        />
      </TopBar> : null}
      <MainContainer paperless>
        {fileCount === 0 ? (
          <Alert severity="info" variant="filled">
            <AlertTitle>Nichts gefunden.</AlertTitle>
            Hier gibt es noch keine Datei.
          </Alert>
        ) : null}
        {fileCount > 0 ? (
          <Paper>
            {project.files.map((x, i) =>
              x.deleted ? null : (
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px 20px",
                    alignItems: "center",
                    gap: "20px",
                    borderBottom: "1px solid #aaa",
                    "&:last-child": { borderBottom: "none" },
                  }}
                >
                  <Box sx={{ fontWeight: "bold", minWidth: "200px" }}>
                    {x.description}
                  </Box>
                  <Box sx={{ color: "#888", minWidth: "300px" }}>
                    {dayjs
                      .utc(x.createdAt, "YYYY-MM-DD HH:mm:ss")
                      .local()
                      .format("ddd, DD.MM.YYYY HH:mm")}{" "}
                    Uhr
                  </Box>
                  <Box>
                    <IconButton href={"https://bob.juchem-gruppe.de/backend/api/image/"+x.filename} target="_blank">
                      <Visibility />
                    </IconButton>
                    {project.writeable ? <Fragment><AddFileDialog
                      save={(o) => saveFile(o, i)}
                      OpenButton={({ onClick }) => (
                        <IconButton onClick={onClick}>
                          <Edit />
                        </IconButton>
                      )}
                      initialState={x}
                      index={i}
                    />
                    <IconButton onClick={() => deleteFile(i)}>
                      <Delete />
                    </IconButton></Fragment> : null}
                  </Box>
                </Box>
              )
            )}
          </Paper>
        ) : null}
      </MainContainer>
    </Box>
  );
}
