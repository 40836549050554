import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { ProjectJob } from "../Project";
import useRights from "@/shared/api/useRights";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";

export default function StatusSelector({
  currentStatus,
  setStatus,
}: {
  currentStatus: ProjectJob["status"];
  setStatus: (status: ProjectJob["status"]) => void;
}) {
  const [requestedChange, setRequestedChange] = useState<
    ProjectJob["status"] | null
  >(null);
  const rights = useRights();
  const isDispo = "DISPOSITION" in rights;

  return (
    <Fragment>
      <ToggleButtonGroup
        sx={{
          ".Mui-selected": {
            background:
              currentStatus === "PLANNING"
                ? "#99D4FF"
                : currentStatus === "RAW"
                ? "#ffed6b"
                : "#A6FF99",
          },
        }}
        orientation="horizontal"
        exclusive
        value={currentStatus}
        onChange={(_, status) => status && (isDispo ? setStatus(status) : setRequestedChange(status))}
      >
        <ToggleButton
          disabled={
            !isDispo && (currentStatus === "FINE" || currentStatus === "RAW")
          }
          value="PLANNING"
        >
          Planung
        </ToggleButton>
        <ToggleButton
          disabled={!isDispo && currentStatus === "FINE"}
          value="RAW"
        >
          Grob
        </ToggleButton>
        <ToggleButton
          disabled={!isDispo && currentStatus === "PLANNING"}
          value="FINE"
        >
          Fein
        </ToggleButton>
      </ToggleButtonGroup>{" "}
      <Dialog
        open={!!requestedChange}
        onClose={() => setRequestedChange(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {requestedChange === "RAW" ? <DialogContentText id="alert-dialog-description">
            Wenn der Status auf Grobplanung steht, kannst du nur noch Material, Mengen und LKW ändern, aber den Tag nicht mehr verschieben!
          </DialogContentText> : <DialogContentText id="alert-dialog-description">
            Wenn der Status auf Feinplanung steht, kannst du an diesem Tag nichts mehr ändern!
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRequestedChange(null)}>
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              !requestedChange || setStatus(requestedChange);
              setRequestedChange(null);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Status ändern
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
