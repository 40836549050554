import ApiService from "@/api/ApiService";
import LabelWrapper from "@/components/root/LabelWrapper";
import {
  Call,
  ChatBubble,
  Check,
  Delete,
  DoneAll,
  Edit,
  Info,
  People,
  PeopleAlt,
  Search,
  Signpost,
  Tag,
  ThumbDown,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export const Rejector = ({ date, orderId }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  const saveMutation = useMutation<
    any,
    AxiosResponse,
    any
  >({
    mutationFn: ApiService.createEntity("resourceOrderRejection"),
    onSuccess: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
      setOpen(false);
    },
  });

  return (
    <Fragment>
      <div className="resOrderButton red" onClick={() => setOpen(true)}>
        <ThumbDown fontSize="inherit" />
      </div>
      {open ? (
        <Dialog open={true} fullWidth maxWidth="md" onClose={() => setOpen(false)}>
          <DialogTitle>Anfrage ablehnen</DialogTitle>
          <DialogContent>
           <TextField
                size="small"
                fullWidth
                multiline
                value={comment}
                placeholder="Beschreibung"
                onChange={(e) =>
                  setComment((e.target as HTMLInputElement).value)
                }
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
            <Button variant="contained" color="error" onClick={() => saveMutation.mutate({date, resourceOrder: orderId, comment})}>
              Ablehnen
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  );
};

const Information = ({ data, did }) => {
  return (
    <Fragment>
      <div
        className={"vc-res-status color "+(!!data.orderSentAt ? "darkgreen" : data.status === "PLANNING"
          ? "blue"
          : data.status === "RAW"
          ? "yellow"
          : "green")}
        
      />
      <div className="infocomp">
        <div className="name">
          {data.phase.project.name + " (" + data.phase.project.number + ")"}
        </div>
        {!!data.resourceOrder ? (
          <Fragment>
            {!!data.orderSentBy ? (
              <div className="info">
                <Call fontSize="inherit" />
                <div className="txt">
                  {data.orderSentBy.firstName + " " + data.orderSentBy.name}
                </div>
              </div>
            ) : null}
            {data.resourceOrder.comment.length > 0 ? (
              <div className="info">
                <ChatBubble fontSize="inherit" />
                <div className="txt">{data.resourceOrder.comment}</div>
              </div>
            ) : null}
          </Fragment>
        ) : did in data.crews && Object.keys(data.crews).length === 1 ? null : (
          <Fragment>
            <div className="info">
              <PeopleAlt fontSize="inherit" />
              <div className="txt">{Object.values(data.crews).join("; ")}</div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default function ResInner({
  elements,
  did,
  i,
  readOnly,
  changeMutation,
  setJobInfoId,
  deleteMutation,
}) {
  const navigate = useNavigate();
  const dates = Object.keys(elements).sort();
  const date = dates[0];
  const element = !!elements[date][i] ? elements[date][i].element : null;

  if (!element) return null;

  return (
    <Fragment>
      <Information data={element} did={did} />
      {!!changeMutation ? (
        <div className="resOrderEdit">
          <div className="resOrderEditTopPart">
            {!readOnly &&
            did !== 0 &&
            !!element.resourceOrder &&
            !element.resourceOrder.singleDayOrder ? (
              <div
                onClick={() =>
                  changeMutation.mutate({
                    move: null,
                    orderToBooking: {
                      order: element.resourceOrder.id,
                      startDate: date,
                      jobId: null,
                      target: did,
                    },
                    bookingMove: null,
                  })
                }
                className="resOrderButton green"
              >
                <DoneAll fontSize="inherit" />
                {!!element?.resourceOrder?.endDate ? <div className="until">&rarr;{element?.resourceOrder?.endDate}</div> : null}
              </div>
            ) : null}
          </div>
          <div className="resOrderEditBottomPart">
            {dates.map((datex) =>
              !!elements[datex][i] && !!elements[datex][i].element ? (
                <div className="resOrderEditDatePart" key={datex}>
                  <div
                    className="resOrderButton"
                    onClick={() => setJobInfoId(elements[datex][i].element.id)}
                  >
                    <Search fontSize="inherit" />
                  </div>
                  {!readOnly && !!elements[datex][i].element.resourceBooking ? (
                    <div
                      className="resOrderButton red"
                      onClick={() =>
                        deleteMutation.mutate(
                          elements[datex][i].element.resourceBooking.id
                        )
                      }
                    >
                      <Delete fontSize="inherit" />
                    </div>
                  ) : !readOnly &&
                    did !== 0 &&
                    !!elements[datex][i].element.resourceOrder ? (
                 
                      <div
                        className="resOrderButton green"
                        onClick={() =>
                          changeMutation.mutate({
                            move: null,
                            orderToBooking: {
                              order:
                                elements[datex][i].element.resourceOrder.id,
                              jobId: elements[datex][i].element.id,
                              target: did,
                            },
                            bookingMove: null,
                          })
                        }
                      >
                        <Check fontSize="inherit" />
                      </div>
                      
                   
                  ) : null}
                  {
                    !readOnly &&
                    !!elements[datex][i].element.resourceOrder ? <Rejector
                    date={elements[datex][i].element.date}
                    orderId={elements[datex][i].element.resourceOrder.id}
                  /> : null
                  }
                </div>
              ) : null
            )}
          </div>
        </div>
      ) : (
        false
      )}
    </Fragment>
  );
}
