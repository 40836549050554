import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";

const client = (() => {
  return axios.create({
    baseURL: import.meta.env.VITE_APP_URL,
  });
})();

export const setToken = function (token: string, refreshToken: string, rights: string) {
  localStorage.setItem(import.meta.env.VITE_APP_NAME+"-token", token);
  localStorage.setItem(import.meta.env.VITE_APP_NAME+"-refreshToken", refreshToken);
  localStorage.setItem(import.meta.env.VITE_APP_NAME+"-rights", rights);
};

const getToken = function () {
  return localStorage.getItem(import.meta.env.VITE_APP_NAME+"-token");
};

const getRefreshToken = function () {
  return localStorage.getItem(import.meta.env.VITE_APP_NAME+"-refreshToken");
};

const fixRights = (rawRights: any) => {
  if (typeof rawRights !== "string")
    return JSON.stringify(rawRights);
  return rawRights;
}

const request = async function (
  options: AxiosRequestConfig,
  authenticated: boolean = true
) {
  if (authenticated)
    options.headers = { Authorization: `Bearer ${getToken()}` };
  try {
    const response = await client(options);
    return response.data;
  } catch (originalError) {
    if (authenticated && originalError.response.status === 401) {
      try {
        const params = new URLSearchParams();
        const refreshToken = getRefreshToken();
        if (!refreshToken) throw originalError.response;
        params.append("refresh_token", refreshToken);
        const refreshTokenResponse = await client({
          url: "/token/refresh",
          method: "POST",
          data: params,
        });
        setToken(
          refreshTokenResponse.data.token,
          refreshTokenResponse.data.refresh_token,
          fixRights(refreshTokenResponse.data?.rights ?? "{}")
        );
      } catch (refreshTokenError) {
       
        throw originalError.response;
      }
      try {
        options.headers = { Authorization: `Bearer ${getToken()}` };
        const response = await client(options);
        return response.data;
      } catch (retryError) {
        
        throw retryError.response;
      }
    } else {
     
      throw originalError.response;
    }
  }
};

export default request;
