import ApiService from "@/api/ApiService";
import { usePersistantStore } from "@/stores/global";
import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Fragment } from "react/jsx-runtime";
import { PossibleCalendarTypes } from "./MainCalendar";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { AxiosResponse } from "axios";

interface OpenRequest {
  date: string;
  resource_tag_id: number;
  open: number;
  name: string;
  type: "RESOURCE" | "CREW" | "HUMAN";
}

export default function OpenRequests({
  setCalendar,
}: {
  setCalendar: (
    type: PossibleCalendarTypes,
    reference: number,
    date: dayjs.Dayjs
  ) => void;
}) {
  const region = usePersistantStore((store) => store.region);
  const dispoCategory = usePersistantStore((store) => store.dispoCategory);
  const setDispoCategory = usePersistantStore(
    (store) => store.setDispoCategory
  );

  

  const query = useQuery<OpenRequest[], AxiosResponse>({
    queryKey: ["getOpenRequests", "" + region, dispoCategory],
    enabled: !!dispoCategory,
    queryFn: ApiService.getOpenRequests(region, dispoCategory),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px -2px 4px #0000004a",
        maxWidth: "250px",
        borderLeft: "1px solid #888",
      }}
    >
      <Box sx={{minWidth: "160px", borderBottom: "1px solid #888"}}>
        <ManyToOneSelect
          sx={{m: 0, "& .MuiOutlinedInput-notchedOutline": {border: "none"}, "& .MuiAutocomplete-clearIndicator": {display: "none !important"}}}
          remoteFilter={{ region: ["region", region] }}
          entity={"dispoCategory"}
          size="small"
          currentId={dispoCategory}
          disabled={false}
          setId={(id) => !id || setDispoCategory(id)}
          showWithoutChoice
          autoSelect
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          overflow: "auto",
          position: "relative",
          background: "#fafafa",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {query.isLoading ? (
          <CircularProgress />
        ) : query.isLoadingError ? (
          "Error"
        ) : (
          (query?.data ?? []).map((x, i, f) => (
            <Fragment key={x.date + "_" + x.resource_tag_id}>
              {i === 0 || f[i - 1].date !== x.date ? (
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    color: "#555",
                    p: "4px 6px",
                    lineHeight: 1,
                    fontWeight: "14px",
                    mb: "8px",
                    flexShrink: 0,
                    background: "#edf0f9",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  {dayjs(x.date).format("DD.MM.YYYY")}
                </Box>
              ) : null}
              <Box
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.5,
                  },
                  display: "flex",
                  border: "1px solid #aaa",
                  mb: "8px",
                  mx: "4px",
                  alignItems: "stretch",
                  overflow: "hidden",
                  borderRadius: "5px",
                  flexShrink: 0,
                  fontSize: "13px",
                }}
                onClick={() =>
                  setCalendar(
                    x.type === "CREW"
                      ? "CREWS"
                      : x.type === "RESOURCE"
                      ? "RESOURCES"
                      : "STAFF",
                    x.resource_tag_id,
                    dayjs(x.date, "YYYY-MM-DD")
                  )
                }
              >
                <Box
                  sx={{
                    borderRight: "1px solid #aaa",
                    color: "#333",
                    boxShadow: "inset -4px 0px 5px rgba(0,0,0,0.1)",
                    p: "4px 6px",
                    lineHeight: 1,
                    background: "#edf0f9",
                  }}
                >
                  {x.open}
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    p: "4px",
                    lineHeight: 1,
                    textTransform: "uppercase",
                  }}
                >
                  {(x.type === "CREW"
                    ? "Kolonne "
                    : x.type === "HUMAN"
                    ? "Mitarbeiter "
                    : "") + x.name}
                </Box>
              </Box>
            </Fragment>
          ))
        )}
      </Box>
    </Box>
  );
}
