import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Fragment, useState } from "react";

const colors: string[][] = [
  ["#b71c1c", "#d32f2f", "#f44336", "#e57373", "#ffcdd2"],
  ["#880e4f", "#c2185b", "#e91e63", "#f06292", "#f8bbd0"],
  ["#4a148c", "#7b1fa2", "#9c27b0", "#ba68c8", "#e1bee7"],
  ["#311b92", "#512da8", "#673ab7", "#9575cd", "#d1c4e9"],
  ["#1a237e", "#303f9f", "#3f51b5", "#7986cb", "#c5cae9"],
  ["#0d47a1", "#1976d2", "#2196f3", "#64b5f6", "#bbdefb"],
  ["#01579b", "#0288d1", "#03a9f4", "#4fc3f7", "#b3e5fc"],
  ["#006064", "#0097a7", "#00bcd4", "#4dd0e1", "#b2ebf2"],
  ["#004d40", "#00796b", "#009688", "#4db6ac", "#b2dfdb"],
  ["#194D33", "#388e3c", "#4caf50", "#81c784", "#c8e6c9"],
  ["#33691e", "#689f38", "#8bc34a", "#aed581", "#dcedc8"],
  ["#827717", "#afb42b", "#cddc39", "#dce775", "#f0f4c3"],
  ["#f57f17", "#fbc02d", "#ffeb3b", "#fff176", "#fff9c4"],
  ["#ff6f00", "#ffa000", "#ffc107", "#ffd54f", "#ffecb3"],
  ["#e65100", "#f57c00", "#ff9800", "#ffb74d", "#ffe0b2"],
  ["#bf360c", "#e64a19", "#ff5722", "#ff8a65", "#ffccbc"],
  ["#3e2723", "#5d4037", "#795548", "#a1887f", "#d7ccc8"],
  ["#263238", "#455a64", "#607d8b", "#90a4ae", "#cfd8dc"],
  ["#000000", "#525252", "#969696", "#D9D9D9", "#FFFFFF"],
];

export const colorsSortedNoBlack = ['#b71c1c', '#880e4f', '#4a148c', '#311b92', '#1a237e', '#0d47a1', '#01579b', '#006064', '#004d40', '#194D33', '#33691e', '#827717', '#f57f17', '#ff6f00', '#e65100', '#bf360c', '#3e2723', '#263238', '#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7', '#00796b', '#388e3c', '#689f38', '#afb42b', '#fbc02d', '#ffa000', '#f57c00', '#e64a19', '#5d4037', '#455a64', '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b', '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d', '#ff8a65', '#a1887f', '#90a4ae', '#ffcdd2', '#f8bbd0', '#e1bee7', '#d1c4e9', '#c5cae9', '#bbdefb', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#c8e6c9', '#dcedc8', '#f0f4c3', '#fff9c4', '#ffecb3', '#ffe0b2', '#ffccbc', '#d7ccc8', '#cfd8dc'];

export const textColors: { [key: string]: string } = {
  "#b71c1c": "#fff",
  "#d32f2f": "#fff",
  "#f44336": "#fff",
  "#e57373": "#fff",
  "#ffcdd2": "#000",
  "#880e4f": "#fff",
  "#c2185b": "#fff",
  "#e91e63": "#fff",
  "#f06292": "#fff",
  "#f8bbd0": "#000",
  "#4a148c": "#fff",
  "#7b1fa2": "#fff",
  "#9c27b0": "#fff",
  "#ba68c8": "#fff",
  "#e1bee7": "#000",
  "#311b92": "#fff",
  "#512da8": "#fff",
  "#673ab7": "#fff",
  "#9575cd": "#fff",
  "#d1c4e9": "#000",
  "#1a237e": "#fff",
  "#303f9f": "#fff",
  "#3f51b5": "#fff",
  "#7986cb": "#fff",
  "#c5cae9": "#000",
  "#0d47a1": "#fff",
  "#1976d2": "#fff",
  "#2196f3": "#fff",
  "#64b5f6": "#fff",
  "#bbdefb": "#000",
  "#01579b": "#fff",
  "#0288d1": "#fff",
  "#03a9f4": "#fff",
  "#4fc3f7": "#fff",
  "#b3e5fc": "#000",
  "#006064": "#fff",
  "#0097a7": "#fff",
  "#00bcd4": "#fff",
  "#4dd0e1": "#fff",
  "#b2ebf2": "#000",
  "#004d40": "#fff",
  "#00796b": "#fff",
  "#009688": "#fff",
  "#4db6ac": "#fff",
  "#b2dfdb": "#000",
  "#194D33": "#fff",
  "#388e3c": "#fff",
  "#4caf50": "#fff",
  "#81c784": "#fff",
  "#c8e6c9": "#000",
  "#33691e": "#fff",
  "#689f38": "#fff",
  "#8bc34a": "#fff",
  "#aed581": "#000",
  "#dcedc8": "#000",
  "#827717": "#fff",
  "#afb42b": "#fff",
  "#cddc39": "#000",
  "#dce775": "#000",
  "#f0f4c3": "#000",
  "#f57f17": "#fff",
  "#fbc02d": "#000",
  "#ffeb3b": "#000",
  "#fff176": "#000",
  "#fff9c4": "#000",
  "#ff6f00": "#fff",
  "#ffa000": "#fff",
  "#ffc107": "#000",
  "#ffd54f": "#000",
  "#ffecb3": "#000",
  "#e65100": "#fff",
  "#f57c00": "#fff",
  "#ff9800": "#fff",
  "#ffb74d": "#000",
  "#ffe0b2": "#000",
  "#bf360c": "#fff",
  "#e64a19": "#fff",
  "#ff5722": "#fff",
  "#ff8a65": "#fff",
  "#ffccbc": "#000",
  "#3e2723": "#fff",
  "#5d4037": "#fff",
  "#795548": "#fff",
  "#a1887f": "#fff",
  "#d7ccc8": "#000",
  "#263238": "#fff",
  "#455a64": "#fff",
  "#607d8b": "#fff",
  "#90a4ae": "#fff",
  "#cfd8dc": "#000",
  "#000000": "#fff",
  "#525252": "#fff",
  "#969696": "#fff",
  "#D9D9D9": "#000",
  "#FFFFFF": "#000",
};

export const randomColor = () => {
  const swatch = colors[Math.floor(Math.random() * colors.length)];
  return swatch[Math.floor(Math.random() * swatch.length)];
};

export const getTextColor = (color: string) => {
  return color in textColors ? textColors[color] : "#000";
};

export default function ColorPicker({
  value,
  onChange,
  size,
  sx={}
}: {
  value: string;
  sx?: any;
  onChange: (newValue: string) => any;
  size: "normal" | "small";
}) {
  const [open, setOpen] = useState(false);

  const onSelect = (color: string) => {
    onChange(color);
    setOpen(false);
  };

  return (
    <Fragment>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          backgroundColor: value,
          alignSelf: "stretch",
          borderRadius: "4px",
          ml: "6px",
          minWidth: "60px",
          border: "1px solid rgba(0,0,0,0.5)",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: getTextColor(value),
          mt: size === "normal" ? "8px" : "7px",
          mb: size === "normal" ? "4px" : "4px",
          "&:hover": {
            opacity: 0.9,
          },
          ...sx
        }}
      >
        <Box sx={{ opacity: 0.5, fontSize: "0.9em" }}>Farbe</Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {colors.map((swatch) => (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {swatch.map((color) => (
                <Box
                  onClick={()=>onSelect(color)}
                  sx={{
                    backgroundColor: color,
                    color: getTextColor(color),
                    minHeight: "40px",
                    cursor: "pointer",
                    border: "1px solid #ddd",
                    marginLeft: "-1px",
                    marginTop: "-1px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {color === value ? <CheckIcon fontSize="large" /> : null}
                </Box>
              ))}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
