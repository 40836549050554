import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import {
  GhostType,
  KGPLocation,
  ResourceBookingType,
  SpBookingType,
} from "../KGP";
import KGPResourceOrder from "./KGPResourceOrder";
import KGPResource from "./KGPResource";
import { useDrop } from "react-dnd";
import ApiService from "@/api/ApiService";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState } from "react";

export default function KGPContainer({
  x,
  date,
}: {
  x: KGPLocation;
  date: string;
}) {
  const [jobSelectorHandler, setJobSelectorHandler] = useState<
    ((jobId: number) => void) | null
  >(null);

  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeKGPCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      queryClient.invalidateQueries({ queryKey: ["getKGP"] });
    },
  });

  const [{ isOver }, drop] = useDrop<
    ResourceBookingType | SpBookingType | GhostType,
    void,
    { isOver: boolean }
  >(
    () => ({
      accept: ["ghost", "spBooking", "resourceBooking"],
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver({shallow: true})
        };
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) return;
        if (x.type === "specialPlace") {
          changeMutation.mutate({
            delete:
              item.type === "resourceBooking" || item.type === "spBooking"
                ? { type: item.type, id: item.id }
                : null,
            create: {
              type: "spBooking",
              resource: item.resource.id,
              target: x.id,
              date,
            },
          });
        } else if (x.type === "project" && Object.keys(x.jobs).length === 1) {
          changeMutation.mutate({
            delete:
              item.type === "resourceBooking" || item.type === "spBooking"
                ? { type: item.type, id: item.id }
                : null,
            create: {
              type: "resourceBooking",
              resource: item.resource.id,
              target: Object.keys(x.jobs)[0],
              date,
            },
          });
        } else if (x.type === "project" && Object.keys(x.jobs).length > 1) {
          setJobSelectorHandler(
            (_currentState: ((jobId: number) => void) | null) =>
              (target: number) => {
                changeMutation.mutate({
                  delete:
                    item.type === "resourceBooking" || item.type === "spBooking"
                      ? { type: item.type, id: item.id }
                      : null,
                  create: {
                    type: "resourceBooking",
                    resource: item.resource.id,
                    target,
                    date,
                  },
                });
                setJobSelectorHandler(null);
              }
          );
        }
      },
    }),
    [x]
  );

  const crew =
    x.type === "project"
      ? Object.values(x.jobs)
          .flatMap((job) => Object.values(job.crews))
          .join("; ")
      : null;

  const nojobs = x.type === "project" && Object.keys(x.jobs).length === 0;
  return (
    <Paper
      ref={
        x.type === "none" || nojobs || (crew !== null && crew.length === 0)
          ? undefined
          : drop
      }
      className={
        "kgpbox " +
        x.type +
        (nojobs ? " ghost" : "") +
        (isOver ? " dropover" : "")
      }
    >
      <div className="kgptitle">
        {x.type === "project" ? (
          <div className="kgptitlenumber">{x.project.number}</div>
        ) : null}
        <div className="kgptitlename">
          {x.type === "project" ? x.project.name : x.name}
        </div>
      </div>
      <div className="kgpboxwrap">
        {x.content.map((y) =>
          y.type === "resourceOrder" ? (
            <KGPResourceOrder date={date} key={y.type + y.id + "_" + y.key} y={y} />
          ) : (
            <KGPResource
              parent={{
                type: x.type,
                id:
                  x.type === "project"
                    ? x.project.id
                    : x.type === "specialPlace"
                    ? x.id
                    : null,
              }}
              y={y}
              key={y.type + y.id}
            />
          )
        )}
      </div>
      {crew !== null ? (
        <div className={"kgpcrew" + (crew.length === 0 ? " nocrew" : "")}>
          {crew || "Keine Kolonne"}
        </div>
      ) : null}
      {!!jobSelectorHandler && x.type === "project" ? (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          onClose={() => setJobSelectorHandler(null)}
        >
          <DialogTitle>Bauphase wählen</DialogTitle>
          <DialogContent className="jobSelectorContainer">
            {Object.values(x.jobs).map((job) => (
              <div
                className="kgpjobSelector"
                key={job.id}
                onClick={() => jobSelectorHandler(job.id)}
              >
                <div>{job.phase.name}</div>
                <div>{Object.values(job.crews).join("; ")}</div>
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setJobSelectorHandler(null)}>
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Paper>
  );
}
