import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Box, Paper } from "@mui/material";
import { useEffect } from "react";
import CalendarListView from "./listView/CalendarListView";
import DispoCalendarSettings from "./dialogs/DispoCalendarSettings";
import DispoCalendarDateSelector from "./calendarElements/DispoCalendarDateSelector";

export default function Disposition() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const type = usePersistantStore((state) => state.type);
  const setType = usePersistantStore((state) => state.setType);
  const reference = usePersistantStore((state) => state.reference);
  const setReference = usePersistantStore((state) => state.setReference);

  useEffect(() => setPageTitle("Disposition"), []);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 1,
          background: "#eee",
        }}
      >
        <Paper
          sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                background: "#edf0f9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                display: "flex",
                columnGap: "5px",
                boxShadow:
                  "inset 0px -4px 5px rgba(0,0,0,0.1)" /*borderBottom: "none"*/,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  ml: "5px",
                  justifyContent: "space-between",
                }}
              >
                <DispoCalendarDateSelector />
                <DispoCalendarSettings />
              </Box>
            </Box>
            <CalendarListView setCalendar={function (_a, _b, _c): void {}} />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
