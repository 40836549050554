import BaseApiService from "@/shared/api/BaseApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ProjectState, SitePosition } from "./planning/Project";
import { CircularProgress, IconButton, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import EnhancedTable from "@/shared/views/EnhancedTable";
import ApiService from "@/api/ApiService";
import { LayersControl, MapContainer, Popup, TileLayer, Tooltip, useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

interface MapData {
  name: string;
  position: SitePosition;
}

const BoundsSetter = ({ boundRequest, positions }) => {
  const map = useMap();
  useEffect(() => {
    const pos = positions.filter(
      (x) =>
        !!x &&
        !(Math.abs(x.lat - 49.565) < 0.01 && Math.abs(x.lng - 7.07) < 0.01)
    );
    if (!pos.length) return;
    map.fitBounds(L.latLngBounds(pos), {
      maxZoom: Math.max(map.getZoom(), 13),
    });
  }, [boundRequest]);
};

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

export default function ProjectMap({ region }) {
  const query = useQuery<MapData[], AxiosResponse>({
    queryKey: ["getMap", ""+region],
    queryFn: ApiService.getMap(region),
  });

  const [boundRequest, setBoundRequest] = useState(0);

  const forceSetBounds = () => {
    setBoundRequest(Math.random());
  };

  useEffect(() => {
    forceSetBounds();
  }, []);

  const positions = query.isSuccess
    ? query.data.filter(x => !!x?.position?.site).map((x) => x.position.site)
    : [];

  return (
    <Paper
      sx={{
        overflow: "hidden",
        gridColumn: "span 2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px"
      }}
    >
      {query.isError ? (
        "Error"
      ) : query.isLoading ? (
        <CircularProgress />
      ) : (
        <MapContainer
          style={{
            background: "white",
            flex: 1,
            alignSelf: "stretch",
            position: "relative",
          }}
          center={[49.565, 7.07]}
          zoom={9}
          scrollWheelZoom={true}
        >
          <BoundsSetter boundRequest={boundRequest} positions={positions} />
          <LayersControl position="bottomleft">
            <LayersControl.BaseLayer checked name="Straßenkarte">
              <TileLayer
                attribution="&copy; HERE"
                url={
                  "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=logistics.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k"
                }
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellit">
              <TileLayer
                attribution="&copy; HERE"
                url={
                  "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=explore.satellite.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k"
                }
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {query.data?.filter(x => !!x?.position?.site).map((x) => (
            <Marker icon={DefaultIcon} position={x.position.site}><Popup>
            {x.name}
          </Popup></Marker>
          ))}
        </MapContainer>
      )}
    </Paper>
  );
}
