import Login from "./components/root/Login";

import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import RootNav from "./components/root/RootNav";
import { useGlobalStore, usePersistantStore } from "./stores/global";
import SetPassword from "./shared/forms/SetPassword";
import Project from "./components/planning/Project";
import TestList from "./components/TestList";
import MainCalendar from "./components/calendar/MainCalendar";
import Settings from "./components/settings/Settings";
import SettingsList from "./components/settings/SettingsList";
import EditUsers from "./shared/forms/EditUsers";
import SettingsEditor from "./components/settings/SettingsEditor";
import Disposition from "./components/calendar/Disposition";
import MixingPlants from "./components/calendar/MixingPlants";
import KGP from "./components/calendar/KGP";


const queryClient = new QueryClient();

const SetPasswordWrapper = () => {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  return <SetPassword setPageTitle={setPageTitle} />;
};

const EditUsersWrapper = () => {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  return <EditUsers hasFirstName setPageTitle={setPageTitle} allowEmptyRights />;
};


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RootNav />
    ),
    children: [
      {
        index: true,
        element: <TestList />,
      },
      {
        path: "project/:id",
        element: <Project />,
      },
      {
        path: "password",
        element: <SetPasswordWrapper />,
      },
      {
        path: "calendar",
        element: <MainCalendar />,
      },
      {
        path: "disposition",
        element: <Disposition />,
      },
      {
        path: "kgp",
        element: <KGP />,
      },
      {
        path: "plants",
        element: <MixingPlants />,
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          {
            index: true,
            element: <Navigate replace to="/settings/resourceTag" />,
          },
          {
            path: "user/:rawId",
            element: <EditUsersWrapper />,
          },
          {
            path: ":type/:rawId",
            element: <SettingsEditor />,
          },
          {
            path: ":type",
            element: <SettingsList />,
          },
        ]
      }
    ],
  },
]);

const App = () => {
  const authenticated = usePersistantStore((state) => state.authenticated);

  return (
    <QueryClientProvider client={queryClient}>
      {authenticated ? <RouterProvider router={router} /> : <Login />}
    </QueryClientProvider>
  );
};

export default App;
