import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import { usePersistantStore } from "@/stores/global";

interface Tag {
  id: number;
  type: "RESOURCE" | "HUMAN" | "CREW";
  name: string;
  region: { id: number; name: string };
}

export default function KGPSelector({
  reference,
  onChange,
}: {
  reference: number | null;
  onChange: (reference: number | null) => void;
}) {
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const region = usePersistantStore((state) => state.region);
  const [regionCheck, setRegionCheck] = useState(region);
  const open2 = !!anchorEl2;
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl2(null);
  }, []);

  const tagNames = useReferenceNames<Tag, Tag>("resourceTag", (o) => o);

  const resources = useMemo(() => {
    const r: Tag[] = [];
    for (const t of tagNames.values())
      if (
        ["CALENDAR_ONLY", "WISHABLE", "REQUESTABLE"].includes(
          t?.calendarType || ""
        ) &&
        (region === 0 || t.region.id === region) &&
        !!t?.usesShortPlanning &&
        t.type === "RESOURCE"
      )
        r.push(t);
    return r;
  }, [tagNames, region]);

  useEffect(() => {
    if (region === regionCheck) return;
    setRegionCheck(region);
    if (tagNames.get(reference ?? 0)) onChange(resources[0].id);
  }, [region]);

  useEffect(() => {
    if (
      reference === 0 && resources.length > 0
    )
      onChange(resources[0].id);
  }, [resources, reference]);



  const title2 = (tagNames.get(reference ?? 0)?.name || "Unbekannt") +
      (region === 0
        ? " (" +
          (tagNames.get(reference ?? 0)?.region.name || "Unbekannt") +
          ")"
        : "");

  return (
    <div>
     
      <Button onClick={handleClick2} endIcon={<ArrowDropDown />}>
          {title2}
        </Button>
      
        <Menu
          open={open2}
          anchorEl={anchorEl2}
          onClose={close}
          slotProps={{
            paper: {
              style: {
                maxHeight: "400px",
              },
            },
          }}
        >
          {resources.map((x) => (
            <MenuItem
              key={x.id}
              onClick={() => {
                onChange(x.id);
                close();
              }}
            >
              {(x.name || "Unbekannt") +
                (region === 0 ? " (" + x.region.name + ")" : "")}
            </MenuItem>
          ))}
        </Menu>
    </div>
  );
}
