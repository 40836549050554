import { usePersistantStore } from "@/stores/global";
import BaseLogin from "@/shared/views/BaseLogin";

export default function Login() {
  const setAuthenticated = usePersistantStore(
    (state) => state.setAuthenticated
  );

  return (
    <BaseLogin
      setAuthenticatedWithUser={setAuthenticated}
      //checkAccessRights={(rights) => "TRANSPLAN" in rights}
      image="/login.jpg"
    />
  );
}
