import { Button, Paper, Box, Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import PlanningCalendar from "../calendarElements/PlanningCalendar";
import VerticalCalendar from "../../shared/VerticalCalendar";
import dayjs from "dayjs";
import { Add, FullscreenExitRounded, Mouse } from "@mui/icons-material";
import { useState } from "react";
import { ProjectJob, ProjectPhase } from "../Project";
import PhaseEditor from "../editors/PhaseEditor";
import DayEditor from "../editors/DayEditor";

interface PlanSelection {
  date: string | null;
  phase: number;
}

export default function PlanningPage({ project, setField, initialSelection, saveProject, setDateChangeNecessary }) {
  const [currentSelection, setCurrentSelection2] =
    useState<PlanSelection>(initialSelection);

  const [weekplanning, setWeekplanning] = useState(false);

  const setCurrentSelection = (x) => {
    setCurrentSelection2(x);
  };

  const setCurrentPhaseField = (changes: Partial<ProjectPhase>) => {
    const phases = project.phases.slice();
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      ...changes,
    };
    setField({ phases });
  };

  const setCurrentDayField = (changes: Partial<ProjectJob>) => {
    if (!currentSelection.date) return;
    const phases = project.phases.slice();
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      jobs: {
        ...phases[currentSelection.phase].jobs,
        [currentSelection.date]: {
          ...phases[currentSelection.phase].jobs[currentSelection.date],
          ...changes,
        },
      },
    };
    setField({ phases });
  };

  const addPhase = () => {
    const phases = project.phases.slice();
    phases.push({
      id: 0,
      name: "",
      trade: null,
      uuid: self.crypto.randomUUID(),
      position: null,
      plannedStart: null,
      plannedEnd: null,
      subcontractor: null,
      deleted: false,
      autoFill: false,
      changeAutoFill: true,
      autoFillExceptions: [],
      comment: "",
      jobs: {},
      resourceOrders: [
        /* {
          id: 0,
          deleted: false,
          amount: 1,
          tag: null,
          comment: "",
          type: "CREW",
          references: [null],
          auto: true,
          exceptions: []
        } */
        //TODO AUTOKOLONNE
      ],
    } as ProjectPhase);
    setField({ phases });
    setCurrentSelection({ date: null, phase: phases.length - 1 });
  };

  let phaseExists = false;
  try {
    phaseExists =
      !currentSelection.date &&
      !!project.phases[currentSelection.phase] &&
      !project.phases[currentSelection.phase].deleted;
  } catch (e) {}

  let dayExists = false;
  try {
    dayExists =
      !!currentSelection.date &&
      !project.phases[currentSelection.phase].deleted &&
      !!project.phases[currentSelection.phase].jobs[currentSelection.date] &&
      !project.phases[currentSelection.phase].jobs[currentSelection.date]
        .deleted;
  } catch (e) {}

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        p: "10px",
        rowGap: "10px",
        overflow: "hidden",
      }}
    >
      {navigator.userAgent.toLowerCase().includes("firefox") ? (
        <Alert sx={{ mb: "10px" }} variant="filled" severity="warning">
          Der Kalender funktioniert im Firefox Browser leider nur eingeschränkt.
          Wir empfehlen deshalb Google Chrome oder Microsoft Edge.
        </Alert>
      ) : null}
      <Paper sx={{ overflow: "hidden" }}>
        <VerticalCalendar
          initialCalEnd={dayjs(project.plannedEnd || "now").endOf("week")}
          initialCalStart={dayjs(project.plannedStart || "now").startOf("week")}
          buttons={
            <div className="buttonbarplanning">{project.writeable ? <Button size="small" onClick={addPhase} startIcon={<Add />}>
              Bauphase
            </Button> : <div/>}<div className="buttonbarmouse"><Mouse /><ToggleButtonGroup
                exclusive
                value={weekplanning}
                size="small"
                onChange={(_, weekplanning) => {
                  if (weekplanning !== null)
                    setWeekplanning(weekplanning);
                }}
              >
                <ToggleButton value={true}>
                  Woche
                </ToggleButton>
                <ToggleButton value={false}>
                  Tag
                </ToggleButton>
              </ToggleButtonGroup></div><div/></div>
          }
        >
          <PlanningCalendar
            weekplanning={weekplanning}
            setWeekplanning={setWeekplanning}
            project={project}
            currentSelection={currentSelection}
            setDateChangeNecessary={setDateChangeNecessary}
            setCurrentSelection={setCurrentSelection}
            setField={setField}
          />
        </VerticalCalendar>
      </Paper>

      {!phaseExists && !dayExists ? (
        <Box
          sx={{
            alignSelf: "center",
            textAlign: "center",
            maxWidth: "400px",
            p: 1,
            opacity: 0.5,
          }}
        >
          Wähle oder erstelle zuerst eine Bauphase
        </Box>
      ) : phaseExists ? (
        <Paper
          sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: !phaseExists ? "center" : "initial",
          }}
        >
          <PhaseEditor
            setProjectField={setField}
            project={project}
            setDateChangeNecessary={setDateChangeNecessary}
            phaseId={currentSelection.phase}
            setField={setCurrentPhaseField}
          />
        </Paper>
      ) : (
        <DayEditor
          setProjectField={setField}
          project={project}
          phaseId={currentSelection.phase}
          date={currentSelection.date}
          saveProject={saveProject}
          setField={setCurrentDayField}
          setPhaseField={setCurrentPhaseField}
        />
      )}
    </Box>
  );
}
