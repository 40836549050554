import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useReferenceNames from "@/shared/forms/useReferenceNames";
import useRights from "@/shared/api/useRights";


interface Plant {
  supplierName: string;
  id: number;
  name: string;
}

export default function SelectPlantDialog({
  reference,
  onChange,
}: {
  reference: number | null;
  onChange: (reference: number | null) => void;
}) {
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = !!anchorEl2;

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl2(null);
  }, []);

  const rights = useRights();
  let plantId = 0;
  if ("PLANT" in rights){
    plantId = parseInt(rights["PLANT"]);
  }

  const plantNames = new Map([[0, {supplierName: "", id: 0, name: "Alle"}], ...useReferenceNames<Plant, Plant>("plant", (o) => o).entries()]);

  useEffect(() => {
    if (plantId > 0) {onChange(plantId);return;}
  }, [ plantNames, plantId]);



  const title2 = ((x) => (!!x ? x.name || x.supplierName : "Unbekannt"))(
        plantNames.get(reference ?? 0));

  return (
    <div>
      
      <Button onClick={plantId === 0 ? handleClick2 : undefined} endIcon={<ArrowDropDown />}>
          {title2}
        </Button><Menu
          open={open2}
          anchorEl={anchorEl2}
          onClose={close}
          slotProps={{
            paper: {
              style: {
                maxHeight: "400px",
              },
            },
          }}
        >
          {Array.from(plantNames.values()).map((x) => (
            <MenuItem
              key={x.id}
              onClick={() => {
                onChange(x.id);
                close();
              }}
            >
              {x.name || x?.supplierName || "Unbekannt"}
            </MenuItem>
          ))}
        </Menu>
    </div>
  );
}
