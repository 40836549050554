import { ArrowForward, ArrowRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

export default function NewRangePicker({
  value,
  onChange,
  small = false,
  maximumDate = undefined,
  minimumDate = undefined,
}: {
  value: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  onChange: (change: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  small: boolean;
  maximumDate: dayjs.Dayjs | undefined;
  minimumDate: dayjs.Dayjs | undefined;
}) {
  return (
    <LocalizationProvider
      adapterLocale="de"
      dateAdapter={AdapterDayjs}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <DatePicker
          slotProps={{ textField: { size: small ? "small" : "medium" } }}
          maxDate={!!value[1] ? value[1] : maximumDate}
          minDate={minimumDate}
          onChange={(v) => onChange([v, !!value[1] ? value[1] : v])}
          value={value[0]}
          displayWeekNumber
        />
        <ArrowForward />
        <DatePicker
          slotProps={{ textField: { size: small ? "small" : "medium" } }}
          minDate={!!value[0] ? value[0] : minimumDate}
          maxDate={maximumDate}
          onChange={(v) => onChange([!!value[0] ? value[0] : v, v])}
          value={value[1]}
          displayWeekNumber
        />
      </Box>
    </LocalizationProvider>
  );
}
