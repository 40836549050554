import { Box, FormControlLabel, Switch, TextField } from "@mui/material";
import { LayersControl, MapContainer, TileLayer, useMap } from "react-leaflet";

import { Fragment, useEffect, useState } from "react";
import whiteA from "@/shared/assets/whiteA.png";
import whiteB from "@/shared/assets/whiteB.png";
import blackA from "@/shared/assets/blackA.png";
import blackB from "@/shared/assets/blackB.png";
import L from "leaflet";
import { DraggableMarker } from "@/shared/maps/DraggableMarker";
import Geocoder from "@/shared/maps/Geocoder";
import { SitePosition as Position } from "../Project";

const BoundsSetter = ({ boundRequest, positions }) => {
  const map = useMap();
  useEffect(() => {
    const pos = positions.filter(
      (x) =>
        !!x &&
        !(Math.abs(x.lat - 49.565) < 0.01 && Math.abs(x.lng - 7.07) < 0.01)
    );
    if (!pos.length) return;
    map.fitBounds(L.latLngBounds(pos), {
      maxZoom: Math.max(map.getZoom(), pos.length > 1 ? 17 : 13),
      paddingTopLeft: [50, 50],
      paddingBottomRight: [270, 50],
    });
  }, [boundRequest]);
  return null;
};

export const PositionEditor = ({
  position,
  onChange,
}: {
  position: Position;
  onChange: (change: Partial<Position>) => any;
}) => {

  
  let mapLat = position.site.lat;
  let mapLng = position.site.lng;
  if (
    position.site.lat === 0 ||
    position.site.lng === 0 ||
    position.site.lat === null ||
    position.site.lng === null
  ) {
    mapLat = 49.565;
    mapLng = 7.07;
  }

  const initialYardPosition = () => {
    if (
      position.yard.lat === 0 ||
      position.yard.lng === 0 ||
      position.yard.lat === null ||
      position.yard.lng === null
    )
      return { lat: mapLat, lng: parseFloat(mapLng) + 0.0025 };
    return position.yard;
  };

  const [boundRequest, setBoundRequest] = useState(0);

  const setPosition = (pos, type) => {
    onChange({
      ...position,
      [type]: pos,
    });
    forceSetBounds();
  };

  const bounds = [{ lat: mapLat, lng: mapLng }];
  if (
    position.useYard &&
    !(
      position.yard.lat === 0 ||
      position.yard.lng === 0 ||
      position.yard.lat === null ||
      position.yard.lng === null
    )
  ) {
    bounds.push(position.yard);
  }

  const forceSetBounds = () => {
    setBoundRequest(Math.random());
  };

  useEffect(() => {
    forceSetBounds();
  }, []);


  return (
    <Fragment>
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
        }}
        center={[49.565, 7.07]}
        zoom={10}
        scrollWheelZoom={true}
      >
        <BoundsSetter boundRequest={boundRequest} positions={bounds} />
        <LayersControl position="bottomleft">
          <LayersControl.BaseLayer checked name="Straßenkarte">
            <TileLayer
              attribution="&copy; HERE"
              url={
                "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=logistics.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k"
              }
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellit">
            <TileLayer
              attribution="&copy; HERE"
              url={
                "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?lang=de&size=512&ppi=400&style=explore.satellite.day&features=vehicle_restrictions:active_and_inactive&apiKey=pegZ-uUwbH06WeXyUQOIsCwaVv_m9u4wCYWAKKJmH6k"
              }
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <DraggableMarker
          noDragEffect
          color={"black"}
          letter={"A"}
          position={{ lat: mapLat, lng: mapLng }}
          setPosition={(x) => setPosition(x, "site")}
          draggable={true}
        />
        {position.useYard ? (
          <DraggableMarker
            noDragEffect
            color={"white"}
            letter={"B"}
            position={position.yard}
            setPosition={(x) => setPosition(x, "yard")}
            draggable={true}
          />
        ) : null}
      </MapContainer>
      <Box
        sx={{
          zIndex: 1200,
          position: "absolute",
          right: "20px",
          top: "20px",
          width: "250px",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            border: "1px solid rgba(0,0,0,0.25)",
            background: "#f9f9f9",
          }}
        >
          <Geocoder
            noPOIs
            setPosition={(site) => {
              onChange({
                ...position,
                site,
                useYard: false,
                yard: { lat: 0, lng: 0 },
              });
              forceSetBounds();
            }}
          />
        </Box>
        <Box
          sx={{
            mt: "10px",
            border: "1px solid rgba(0,0,0,0.25)",
            background: "#f9f9f9",
          }}
        >
          <FormControlLabel
            sx={{ padding: "10px", py: 0 }}
            control={
              <Switch
                checked={position.useYard}
                onChange={(_, useYard) => {
                  onChange({
                    ...position,
                    yard: initialYardPosition(),
                    useYard,
                  });
                  forceSetBounds();
                }}
              />
            }
            label="Lagerplatz"
          />
          {position.useYard ? (
            <Box
              sx={{
                padding: "5px 10px",
                borderTop: "1px solid #aaa",
                borderBottom: "1px solid #aaa",
                fontSize: "90%",
                background: "#eee",
                alignItems: "center",
                display: "grid",
                gridTemplateColumns: "30px auto",
                gridTemplateRows: "30px 30px",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left center",
            
                  alignSelf: "stretch",
                  backgroundImage:
                    "url(" + (blackA) + ")",
                  backgroundSize: "contain",
                }}
              />
              <Box >
                Entladestelle
              </Box>
              <Box
            
                sx={{
                  backgroundRepeat: "no-repeat",
               
                  backgroundPosition: "left center",
                  alignSelf: "stretch",
                  backgroundImage:
                    "url(" + (whiteB ) + ")",
                  backgroundSize: "contain",
                }}
              />
              <Box>
                Lagerplatz
              </Box>
            </Box>
          ) : null}
          <FormControlLabel
            sx={{ padding: "10px", py: 0 }}
            control={
              <Switch
                checked={position.onlySolo}
                onChange={(_, onlySolo) => {
                  onChange({
                    ...position,
                    onlySolo,
                  });
                }}
              />
            }
            label="Nur Solo, kein Sattel"
          />
          <Box
            sx={{
              p: "5px",
              pt: position.comment.length ? "8px" : "5px",
              borderTop: "1px solid #aaa",
              background: "#eee",
            }}
          >
            <TextField
              label="Anfahrtshinweise"
              value={position.comment}
              onChange={(e) =>
                onChange({
                  ...position,
                  comment: (e.target as HTMLInputElement).value,
                })
              }
              multiline
              sx={{ background: "white" }}
              size="small"
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
