import { useGlobalStore, usePersistantStore } from "@/stores/global";
import { Box, CircularProgress, Paper } from "@mui/material";
import { useEffect } from "react";
import DispoCalendarDateSelector from "./calendarElements/DispoCalendarDateSelector";
import KGPSelector from "./dialogs/KGPSelector";
import ApiService from "@/api/ApiService";
import { ResultOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CalJob, CalProject } from "./calendarElements/InnerCalendar";
import { TopBar } from "@/shared/structure/TopBar";
import KGPContainer from "./kgp/KGPContainer";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CustomDragLayer } from "./kgp/CustomDragLayer";

interface Resource {
  id: number;
  name: string;
  number: string;
  lastLocation: any | null;
}

interface ResourceOrder {
  id: number;
  comment: string;
}

export interface ResourceOrderType extends ResourceOrder {
  key: number;
  type: "resourceOrder";
  wish: Resource | null;
  job: number;
}

export interface ResourceBookingType {
  id: number;
  type: "resourceBooking";
  resource: Resource;
  job: number;
  resourceOrder: ResourceOrder;
}

export interface SpBookingType {
  id: number;
  type: "spBooking";
  resource: Resource;
}

export interface GhostType {
  id: number;
  type: "ghost";
  resource: Resource;
}

interface NoneType {
  id: 0;
  type: "none";
  name: string;
  content: GhostType[];
}

interface SpecialPlaceType {
  id: number;
  type: "specialPlace";
  name: string;
  content: (GhostType | SpBookingType)[];
}

interface ProjectType {
  id: number;
  type: "project";
  jobs: { [id: number]: CalJob };
  project: CalProject;
  content: (ResourceOrderType | ResourceBookingType | GhostType)[];
}

export type KGPLocation = NoneType | SpecialPlaceType | ProjectType;

type KGPLocationType = KGPLocation["type"]; // Extract the literal union of types

const typeSorting: Record<KGPLocationType, number> = {
  none: 1,
  specialPlace: 2,
  project: 3,
};

export default function KGP() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);
  const kgpReference = usePersistantStore((state) => state.kgpReference);
  const date = useGlobalStore((state) => state.date);
  const region = usePersistantStore((state) => state.region);
  const setKgpReference = usePersistantStore((state) => state.setKgpReference);
  useEffect(() => setPageTitle("Kleingeräteplanung"), []);

  const query = useQuery<KGPLocation[], AxiosResponse>({
    queryKey: ["getKGP", "" + kgpReference, date, "" + region],
    queryFn: ApiService.getKGP(kgpReference, date, region),
  });

  return (
    <DndProvider backend={HTML5Backend}><Box
      sx={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 0,
          background: "#eee",
        }}
      >
        <TopBar>
          <KGPSelector reference={kgpReference} onChange={setKgpReference} />
          <DispoCalendarDateSelector />
        </TopBar>
        {query.isLoading ? (
          <CircularProgress />
        ) : query.isLoadingError ? (
          "Error"
        ) : (
          <div className="kgpwrapper">
            {query.data
              ?.sort((a, b) => {
                if (a.type !== b.type)
                  return typeSorting[a.type] < typeSorting[b.type] ? -1 : 1;
                if (a.type === "project")
                  return a.project.name.localeCompare(b.project.name);
                if (a.type === "none" || a.type === "specialPlace")
                  return a.name.localeCompare(b.name);
                return 0;
              })
              .map((x) => <KGPContainer date={date} key={x.type + x.id} x={x} />)}
          </div>
        )}
      </Box>
    </Box><CustomDragLayer /></DndProvider>
  );
}
