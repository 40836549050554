import { usePersistantStore, useGlobalStore } from "@/stores/global";
import RegionSelector from "./RegionSelector";
import BaseRootNav from "@/shared/structure/RootNav";
import BobDark from "@/assets/images/bob_dark.png";
import {
  LocalShippingTwoTone,
  CalendarMonthTwoTone,
  SettingsTwoTone,
  Home,
  CalendarMonth,
  Settings,
  Today,
  Landscape,
  Construction,
} from "@mui/icons-material";
import useRights from "@/shared/api/useRights";

export default function RootNav() {
  const setAuthenticated = usePersistantStore(
    (state) => state.setAuthenticated
  );

  const rights = useRights();

  const pageTitle = useGlobalStore((state) => state.pageTitle);

  return (
    <BaseRootNav
      image={BobDark}
      name={pageTitle}
      menu={[
        !("PLANT" in rights) ?{
          Icon: Home,
          to: "/",
          text: "Übersicht",
          end: true,
        }:null,!("PLANT" in rights) ?{
          Icon: CalendarMonth,
          to: "calendar",
          text: "Kalender",
          end: true,
        }:null,
        !("PLANT" in rights) ?{
          Icon: Construction,
          to: "kgp",
          text: "Kleingeräte",
          end: true,
        }:null,
        !("PLANT" in rights) ?{
          Icon: Today,
          to: "disposition",
          text: "Disposition",
          end: true,
        }:null,
        {
          Icon: Landscape,
          to: "plants",
          text: "Lieferungen",
          end: true,
        },("BASEDATA" in rights) ? {
          Icon: Settings,
          to: "settings",
          text: "Stammdaten",
          end: false,
        } : null,
        
      ].filter(x => !!x)}
      setAuthenticated={setAuthenticated}
      elements={[<RegionSelector key="ks" />]}
    />
  );
}
